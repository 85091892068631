import { FormType } from '@/constant/form';
import { YMDHMS } from '@/constant/timeFormat';
import Table from '@/constant/table';
import moment from '@/utils/moment';

import {
  WAVE_ORDER_STSTUS_ENUM,
} from './constant';

const { TIME_COLUMN_WIDTH } = Table;

export const TABLECOLUMN = [
  {
    type: 'selection',
    width: 55,
  }, {
    label: '序号',
    type: 'index',
    width: 50,
  }, {
    label: '出库单号',
    prop: 'deliverOrderNo',
    minWidth: 160,
  }, {
    label: '提货单号',
    prop: 'ladingOrderNo',
    minWidth: 160,
  }, {
    label: '波次号',
    prop: 'waveOrderNo',
    minWidth: 160,
  }, {
    label: '波次状态',
    prop: 'waveOrderStatus',
    minWidth: 80,
    formatter: (row) => WAVE_ORDER_STSTUS_ENUM[row.waveOrderStatus],
  }, {
    label: '货主',
    prop: 'shipperName',
    minWidth: 120,
  }, {
    label: '站点名称',
    prop: 'stationName',
    minWidth: 140,
  }, {
    label: '线路编号',
    prop: 'lineId',
    minWidth: 160,
  }, {
    label: '线路名称',
    prop: 'lineName',
    minWidth: 140,
  }, {
    label: '订单创建时间',
    prop: 'createTime',
    minWidth: TIME_COLUMN_WIDTH,
    formatter: (row) => moment.format(row.createTime),
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    minWidth: TIME_COLUMN_WIDTH,
    formatter: (row) => moment.format(row.bookingTime),
  }, {
    label: '外部单号',
    prop: 'externalOrderNo',
    minWidth: 160,
  }, {
    label: '客户订单号',
    prop: 'customerOrderNo',
    minWidth: 160,
  }, {
    label: '发货备注',
    prop: 'remark',
    minWidth: 160,
  },
];

export const serchFields = [
  {
    label: '出库单号',
    prop: 'deliverOrderNo',
    component: FormType.INPUT,
  }, {
    label: '波次号',
    prop: 'waveNo',
    component: FormType.INPUT,
  }, {
    label: '货主',
    prop: 'shipperId',
  }, {
    label: '外部单号',
    prop: 'externalOrderNo',
    component: FormType.INPUT,
  }, {
    label: '客户订单号',
    prop: 'customerOrderNo',
    component: FormType.INPUT,
  }, {
    label: '配送站点',
    prop: 'stationName',
    component: FormType.INPUT,
  }, {
    label: '线路',
    prop: 'lineName',
    component: FormType.INPUT,
  }, {
    label: '提货单号',
    prop: 'ladingOrderNo',
    component: FormType.INPUT,
  }, {
    label: '创建时间',
    prop: 'createTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
      valueFormat: YMDHMS,
    },
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'daterange',
      default: [],
      valueFormat: YMDHMS,
    },
  },
];
