<template>
  <el-dialog
    v-model="visibleBatch"
    title="批量发运"
  >
    <div>是否对勾选的订单批量发运？</div>
    <el-form :model="shipConfirm" label-position="left">
      <el-form-item
        v-if="branchIncludeHandling"
        label="是否外包装卸:"
        label-width="120px"
        :rules="[
          {
            required: true,
            message: '请选择',
            trigger: ['blur','change'],
          },
        ]"
      >
        <el-select
          v-model="shipConfirm.outsourceHandling"
          placeholder="请选择"
        >
          <el-option
            v-for="item in URGENT"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <nh-button @click="dialogCancelBatch">
          取 消
        </nh-button>
        <nh-button
          v-loading="loading.dialogConfirmBatch"
          type="primary"
          @click="dialogConfirmBatch"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import loadingMixin from '@thales/loading';
import { transportConfirmNew } from '../api';
import { URGENT } from '../constant';

export default {
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      URGENT,
      visibleBatch: false,
      selectLists: [],
      shipConfirm: {
        outsourceLoadAndUnload: true,
      },
    };
  },
  computed: {
    branchIncludeHandling() {
      return this.selectLists.some((item) => item.includeHandling);
    },
  },
  methods: {
    init(selectLists) {
      this.selectLists = selectLists;
      this.visibleBatch = true;
    },
    dialogCancelBatch() {
      this.visibleBatch = false;
    },
    async dialogConfirmBatch() {
      const data = this.selectLists.map((item) => ({
        ...item,
        deliverTransportId: item.transportId,
        deliverOrderIds: [item.deliverOrderId],
        confirmType: 'ORDER',
        outsourceHandling: this.branchIncludeHandling ? this.shipConfirm.outsourceHandling : null,
      }));
      await transportConfirmNew(data);
      this.$message({
        message: '操作成功',
        type: 'success',
      });
      this.$emit('onSuccess');
      this.dialogCancelBatch();
    },
  },
};
</script>
