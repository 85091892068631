import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 按订单发运分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/18435
 */
export function pageByOrder(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/page/by/order', data, { params });
}
/**
 * @description: 批量发运确认
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/17981
 */
export function batchConfirm(data) {
  return wmsPlusHttp.post('/warehouse_management_system/transport/batch_confirm', data);
}
/**
 * @description: 批量发运确认新接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19279
 */
export function transportConfirmNew(data) {
  return wmsPlusHttp.post('/warehouse_management_system/loading/deliver/batch_confirm', data);
}
