<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="formData.shipperId"
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      border
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="getTableData"
    >
      <template #tableHeaderRight>
        <nh-button
          v-loading="loading.handleBatchConfirm"
          :track="{
            trackName: '出库管理/发运-按订单/[批量发运]'
          }"
          @click="handleBatchConfirm"
        >
          批量发运
        </nh-button>
      </template>
      <template #deliverOrderNo="{row}">
        <nh-link
          :to="{}"
          :text="row.deliverOrderNo"
          @click="headleOndetail(row.deliverOrderId)"
        />
      </template>
    </PlTable>
  </pl-block>
  <detail ref="detail" />
  <batch-confirm ref="BatchConfirm" @onSuccess="getTableData" />
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import OutWarehouse from '@/constant/pageNames/outWarehouse';
import BatchConfirm from './batchConfirm/index.vue';
import Detail from '../deliver-order/detail/index.vue';
import { pageByOrder } from './api';
import { TABLECOLUMN, serchFields } from './fileds';

export default {
  name: OutWarehouse.DELIVER_ORDER,

  components: {
    Detail,
    BatchConfirm,
  },
  mixins: [loadingMixin],
  provide: {
    nameZh: '发运-按订单',
  },
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      tableData: [],
      formData: {
        shipperId: null,
      },
      loading: {
        getTableData: false,
        handleBatchConfirm: false,
      },
      selectLists: [],
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQueryFrom(data) {
      const formData = {
        ...data,
        shipperId: this.formData.shipperId || null,
      };
      Object.keys(formData).forEach((item) => {
        formData[item] = formData[item] === '' ? null : formData[item];
      });
      this.pagination.page = 1;
      // 调用表单重置 时间控件会跳到 1970 年，增加default 解决问题后 导致 重置 会携带默认值，所以在这里判定，只有一个值的时候即为重置
      const createTime = !(formData.createTime && formData.createTime.length)
        || (formData.createTime
          && formData.createTime instanceof Array
          && formData.createTime.length < 2);
      const bookingTime = !(formData.bookingTime && formData.bookingTime.length)
        || (formData.bookingTime
          && formData.bookingTime instanceof Array
          && formData.bookingTime.length < 2);
      if (createTime) {
        formData.createTime = [];
      }
      if (bookingTime) {
        formData.bookingTime = [];
      }

      this.formData = {
        ...formData,
        beforeCreateTime:
          formData.createTime
          && formData.createTime[0]
          && moment(formData.createTime[0]).startOf('day'),
        afterCreateTime:
          formData.createTime
          && formData.createTime[1]
          && moment(formData.createTime[1]).endOf('day'),
        beforeBookingTime:
          formData.bookingTime
          && formData.bookingTime[0]
          && moment(formData.bookingTime[0]).startOf('day'),
        afterBookingTime:
          formData.bookingTime
          && formData.bookingTime[1]
          && moment(formData.bookingTime[1]).endOf('day'),
      };
      this.getTableData();
    },
    async handleDeliverOrderClose() {
      if (this.getSelectDeliverOrderIds(this.selectIds).length <= 0) {
        await this.$message({
          message: '必须选择一条数据后才能进行取消',
          type: 'warning',
        });
      } else {
        await this.$confirm('是否取消该出库单？', '订单取消', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        });
        await this.deliverOrderCancel(this.getSelectDeliverOrderIds(this.selectIds));
      }
    },
    handleSelectionChange(selection) {
      this.selectLists = selection;
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await pageByOrder(pagination, this.formData);
      this.tableData = result.records;
      this.pagination.total = result.total;
    },
    async handleBatchConfirm() {
      if (this.selectLists.length <= 0) {
        await this.$message({
          message: '必须选择一条数据后才能进行批量发运',
          type: 'warning',
        });
      } else {
        this.$refs.BatchConfirm.init(this.selectLists);
      }
    },

    headleOndetail(id) {
      this.$refs.detail.init(id);
    },
  },
};
</script>
<style scoped lang="scss">
.action {
  padding: 15px 0;
}

.full-content {
  :deep(.cursor) {
    cursor: pointer;
  }
}

.cursor-color {
  color: aquamarine;
}

.warm-tips > p {
  padding: 4px 0;
}
</style>
