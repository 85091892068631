export const WAVE_COMPOSE_TYPE_ENUM = {
  BATCH: 'BATCH',
  SHIPPING_ORDER: 'SHIPPING_ORDER',
  CUSTOMER: 'CUSTOMER',
};

export const SOURCE = [
  {
    label: 'OMS',
    value: 'OMS',
  },
  {
    label: 'WMS',
    value: 'WMS',
  },
];
export const SOURCE_ENUM = SOURCE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const PICKUP_TYPE = [
  {
    label: '客户自提',
    value: 'CUSTOMER_DELIVERY',
  },
  {
    label: 'TMS配送',
    value: 'TMS_DELIVERY',
  },
];
export const PICKUP_TYPE_ENUM = PICKUP_TYPE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const URGENT = [
  {
    label: '是',
    value: true,
  },
  {
    label: '否',
    value: false,
  },
];

export const WAVE_ORDER_STSTUS = [
  { value: 'WAITING_DISTRIBUTE ', label: '待下发' },
  { value: 'DISTRIBUTING', label: '批量下发下发中' },
  { value: 'DISTRIBUTE', label: '已下发' },
  { value: 'CANCELED', label: '已取消' },
  { value: 'PICKING', label: '拣货中' },
  { value: 'PICK_COMPLETED', label: '拣货完成' },
];
export const WAVE_ORDER_STSTUS_ENUM = WAVE_ORDER_STSTUS
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});

export const DELIVER_TYPE = [{
  value: 'ORDER_OUT',
  label: '订单出库',
},

{
  value: 'ALLOT_OUT',
  label: '调拨出库',
},

{
  value: 'SHIFT_PARKING_OUT',
  label: '移库移位(出)',
},

{
  value: 'ADJUST_OUT',
  label: '调整出库',
},

{
  value: 'INVENTORY_LESS',
  label: '盘亏出库',
},

{
  value: 'DESTROY_OUT',
  label: '销毁出库',
},

{
  value: 'CANCEL_OUT',
  label: '退供出库',
},

{
  value: 'TOTAL_PICK_DOWN',
  label: '总拣下架',
},

{
  value: 'TEMPORARY_OUT',
  label: '零担出库',
},

{
  value: 'OTHER_IT_OUT',
  label: '其它出库',
},

{
  value: 'OTHER_HE_OUT',
  label: '其他出库',
},

{
  value: 'ALLOT_OUT_BOUND_IN',
  label: '调拨出库(库内)',
}];
export const DELIVER_TYPE_ENUM = DELIVER_TYPE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});

export const TEMPERATURE_LAYER_CODE = [
  { value: '1', label: '常温' },
  { value: '3', label: '冷藏' },
  { value: '2', label: '冷冻' },
  { value: '4', label: '恒温' },
];
export const TEMPERATURE_LAYER_CODE_NEW = [
  { value: 'NORMAL', label: '常温' },
  { value: 'REFRIGERATE', label: '冷藏' },
  { value: 'COLD', label: '冷冻' },
  { value: 'CONSTANT', label: '恒温' },
];
export const TEMPERATURE_LAYER_TO_NEW_ENUM = {
  1: 'NORMAL',
  3: 'REFRIGERATE',
  2: 'COLD',
  4: 'CONSTANT',
};
export const TEMPERATURE_LAYER_CODE_ENUM = TEMPERATURE_LAYER_CODE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
export const TEMPERATURE_LAYER_CODE_NEW_ENUM = TEMPERATURE_LAYER_CODE_NEW
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
// 可下载文件类型
export const FILE_TYPE = [
  {
    ext: '.aac',
    value: 'audio/aac',
  },
  {
    ext: '.abw',
    value: 'application/x-abiword',
  },
  {
    ext: '.arc',
    value: 'application/x-freearc',
  },
  {
    ext: '.avi',
    value: 'video/x-msvideo',
  },
  {
    ext: '.azw',
    value: 'application/vnd.amazon.ebook',
  },
  {
    ext: '.bin',
    value: 'application/octet-stream',
  },
  {
    ext: '.bmp',
    value: 'image/bmp',
  },
  {
    ext: '.bz',
    value: 'application/x-bzip',
  },
  {
    ext: '.bz2',
    value: 'application/x-bzip2',
  },
  {
    ext: '.csh',
    value: 'application/x-csh',
  },
  {
    ext: '.css',
    value: 'text/css',
  },
  {
    ext: '.csv',
    value: 'text/csv',
  },
  {
    ext: '.doc',
    value: 'application/msword',
  },
  {
    ext: '.docx',
    value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    ext: '.eot',
    value: 'application/vnd.ms-fontobject',
  },
  {
    ext: '.epub',
    value: 'application/epub+zip',
  },
  {
    ext: '.gif',
    value: 'image/gif',
  },
  {
    ext: '.htmn',
    value: 'text/html',
  },
  {
    ext: '.html',
    value: 'text/html',
  },
  {
    ext: '.ico',
    value: 'image/vnd.microsoft.icon',
  },
  {
    ext: '.ics',
    value: 'text/calendar',
  },
  {
    ext: '.jar',
    value: 'application/java-archive',
  },
  {
    ext: '.jpg',
    value: 'image/jpeg',
  },
  {
    ext: '.jpeg',
    value: 'image/jpeg',
  },
  {
    ext: '.js',
    value: 'text/javascript',
  },
  {
    ext: '.json',
    value: 'application/json',
  },
  {
    ext: '.jsonld',
    value: 'application/ld+json',
  },
  {
    ext: '.midi',
    value: 'audio/midi audio/x-midi',
  },
  {
    ext: '.midn',
    value: 'audio/midi audio/x-midi',
  },
  {
    ext: '.mjs',
    value: 'text/javascript',
  },
  {
    ext: '.mp3',
    value: 'audio/mpeg',
  },
  {
    ext: '.mpeg',
    value: 'video/mpeg',
  },
  {
    ext: '.mpkg',
    value: 'application/vnd.apple.installer+xml',
  },
  {
    ext: '.odp',
    value: 'application/vnd.oasis.opendocument.presentation',
  },
  {
    ext: '.ods',
    value: 'application/vnd.oasis.opendocument.spreadsheet',
  },
  {
    ext: '.odt',
    value: 'application/vnd.oasis.opendocument.text',
  },
  {
    ext: '.oga',
    value: 'audio/ogg',
  },
  {
    ext: '.ogv',
    value: 'video/ogg',
  },
  {
    ext: '.ogx',
    value: 'application/ogg',
  },
  {
    ext: '.otf',
    value: 'font/otf',
  },
  {
    ext: '.png',
    value: 'image/png',
  },
  {
    ext: '.pdf',
    value: 'application/pdf',
  },
  {
    ext: '.ppt',
    value: 'application/vnd.ms-powerpoint',
  },
  {
    ext: '.pptx',
    value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  {
    ext: '.rar',
    value: 'application/x-rar-compressed',
  },
  {
    ext: '.rtf',
    value: 'application/rtf',
  },
  {
    ext: '.sh',
    value: 'application/x-sh',
  },
  {
    ext: '.svg',
    value: 'image/svg+xml',
  },
  {
    ext: '.swf',
    value: 'application/x-shockwave-flash',
  },
  {
    ext: '.tar',
    value: 'application/x-tar',
  },
  {
    ext: '.tiff',
    value: 'image/tiff',
  },
  {
    ext: '.tif',
    value: 'image/tiff',
  },
  {
    ext: '.ttf',
    value: 'font/ttf',
  },
  {
    ext: '.txt',
    value: 'text/plain',
  },
  {
    ext: '.vsd',
    value: 'application/vnd.visio',
  },
  {
    ext: '.wav',
    value: 'audio/wav',
  },
  {
    ext: '.weba',
    value: 'audio/webm',
  },
  {
    ext: '.webm',
    value: 'video/webm',
  },
  {
    ext: '.webp',
    value: 'image/webp',
  },
  {
    ext: '.woff',
    value: 'font/woff',
  },
  {
    ext: '.woff2',
    value: 'font/woff2',
  },
  {
    ext: '.xhtml',
    value: 'application/xhtml+xml',
  },
  {
    ext: '.xls',
    value: 'application/vnd.ms-excel',
  },
  {
    ext: '.xlsx',
    value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    ext: '.xml',
    value: 'application/xml',
  },
  {
    ext: '.xul',
    value: 'application/vnd.mozilla.xul+xml',
  },
  {
    ext: '.zip',
    value: 'application/zip',
  },
  {
    ext: '.3gp',
    value: 'video/3gpp',
  },
  {
    ext: '.3g2',
    value: 'video/3gpp2',
  },
  {
    ext: '.7z',
    value: 'application/x-7z-compressed',
  },
];
export const FILE_TYPE_ENUM = FILE_TYPE
  .reduce((total, item) => ({ ...total, [item.ext]: item.value }), {});
